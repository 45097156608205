// src/theme.js
import { createTheme } from '@mui/material/styles';
import { green, orange } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    mode: 'dark',
    // primary: green,
    // secondary: orange,
  },
});

export default theme;
