// src/App.js
import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  TextField,
  Button,
  CssBaseline,
  Box,
  FormControl,
  MenuItem,
  InputLabel,
} from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import theme from './theme';

const App = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [language, setLanguage] = useState('English');
  const calendarUrl =
  'https://calendar.google.com/calendar/embed?height=600&wkst=1&ctz=Asia%2FSingapore&bgcolor=%23ffffff&mode=WEEK&src=ZjBiNDM5NjlmYTY5YzMwMTA0MjVhYzhjYzI1ZDc4OTFiZGUwNzExYzg2NWQwNGNlODY1NTM3MTMyOWI5M2IwNkBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%23F09300';
  const baseUrl = 'https://doc-appointment-v3.onrender.com'; // "https://doc-appointment-sq5n.onrender.com";

  const handleSubmit = (event) => {
    event.preventDefault();
    fetch(baseUrl + '/startcall', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        phoneNumber: phoneNumber,
        language: language,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        alert(data.message);
        setPhoneNumber('');
        setLanguage('English');
      })
      .catch((error) => console.error('Error:', error));
  };

  const reloadIFrame = () => {
    document.getElementById('gcal').src = calendarUrl;
  };

  useEffect(() => {
    document.title = "Appointment Scheduler";
    const interval = setInterval(() => {
      fetch(baseUrl + '/calendar-status')
        .then((response) => response.json())
        .then((data) => {
          if (data.updated_calendar) {
            reloadIFrame();
          }
        })
        .catch((error) => console.error('Error:', error));
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6">Ringer.AI</Typography>
        </Toolbar>
      </AppBar>
      <Container>
        <Box my={4}>
          <Typography variant="h4" align="center" gutterBottom>
            Appointment Scheduler
          </Typography>
          <Container maxWidth="sm">
            <Typography paragraph>
              Demonstrating the capability of Ringer.AI's Duet Agent which is customized to schedule appointments.
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate>
              <FormControl fullWidth margin="normal">
                <TextField
                  label="Enter your phone number"
                  variant="outlined"
                  required
                  size="small"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </FormControl>
              <FormControl fullWidth margin="normal">
                <TextField
                  select
                  label="Choose conversation language"
                  value={language}
                  onChange={(e) => setLanguage(e.target.value)}
                  fullWidth
                  margin="normal"
                  size="small"
                  required
                >
                  {['English', 'Hindi'].map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
              <Button type="submit" variant="contained" color="primary" size="small" fullWidth sx={{ mt: 2 }}>
                Request phone call
              </Button>
            </Box>
          </Container>
          <Box mt={5}>
            <iframe
              id="gcal"
              style={{ border: 'solid 1px #777', width: '100%', height: '600px' }}
              frameBorder="0"
              scrolling="no"
              src={calendarUrl}
              title="Google Calendar"
            ></iframe>
            <Button onClick={reloadIFrame} variant="contained" color="secondary" size="small">
              Refresh Calendar
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default App;
